"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMBEDDED_AD_URL_SLUG_TO_UPGRADE_CARD_TYPE_MAP = exports.UPGRADE_CARD_TYPE_TO_EMBEDDED_AD_URL_SLUG_MAP = exports.UpgradeCardType = void 0;
var UpgradeCardType;
(function (UpgradeCardType) {
    UpgradeCardType["creditCardBalanceTransfer"] = "creditCardBalanceTransfer";
    UpgradeCardType["personalLoanRefi"] = "personalLoanRefi";
    UpgradeCardType["genericAvenCash"] = "genericAvenCash";
    UpgradeCardType["genericMoap"] = "genericMoap";
    UpgradeCardType["dryrunMoap"] = "dryrunMoap";
    UpgradeCardType["none"] = "none";
})(UpgradeCardType = exports.UpgradeCardType || (exports.UpgradeCardType = {}));
// This map is used to get the slug for the embedded ad url for the given upgrade card type
exports.UPGRADE_CARD_TYPE_TO_EMBEDDED_AD_URL_SLUG_MAP = {
    [UpgradeCardType.genericMoap]: 'generic_moap',
    [UpgradeCardType.genericAvenCash]: 'generic_aven_cash',
    [UpgradeCardType.creditCardBalanceTransfer]: 'credit_card_balance_transfer',
    [UpgradeCardType.personalLoanRefi]: 'personal_loan_refi',
    // TODO(ani): implement the rest
    [UpgradeCardType.dryrunMoap]: null,
};
// Reverse the map above
exports.EMBEDDED_AD_URL_SLUG_TO_UPGRADE_CARD_TYPE_MAP = Object.fromEntries(Object.entries(exports.UPGRADE_CARD_TYPE_TO_EMBEDDED_AD_URL_SLUG_MAP)
    .map(([key, value]) => [value, key])
    // filter out null values
    .filter(([_, value]) => value !== null));
